import React from 'react'
import Navbar from './NavBar'
import bannerData from '../data/bannerData'

const Page404 = ({posts}) => {
  return (
    <>
    <Navbar posts={posts} />
      Veljko Page is not found
    </>
  )
}

export default Page404
