import React from 'react';
import { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import axios from 'axios';
import Home from './pages/home/Home';
import Webinar from './pages/webinar/Webinar'
import PrivacyPolicy from './pages/PrivacyPolicy';
import Page404 from './components/Page404';
import Popup from './pages/Popup';
import AiBlog from './pages/ai-blog/AiBlog'
import AiTools from './pages/ai-tools/AiTools'
import AiTrader from './pages/ai-trader/AiTrader';
import SinglePost from './pages/SinglePost';


function App() {


  const [posts, setPosts] = useState([]);




// Wen React rendres this component start this useEffect and get data with api.With Axios which automaticly parses object from json to regular js object, use that axios response object and create new object with map and excrat only specific data, then useState to rerender and add this data to UI
  useEffect(() => {

      // I must add embed query so i can extract image data.
      axios.get('https://www.top500ai.com/top500ai_wordpress/wp-json/wp/v2/posts?_embed')
          .then(response => {
              const simplifiedPosts = response.data.map(post => ({
                  id: post.id,
                  // This .rendered is for displaying as html it is concecnted with dangerouslySetInnerHTML
                  title: post.title.rendered,
                  content: post.content.rendered,
                  // In post object we have "embeded" key properties and if it's value is not null or undefined, then to inside and locate wp:featuredmeda (we must put it in [] because I cannot write post._embedded.wp:featuredmedia - because of " : ")
                  featuredImage: post._embedded?.["wp:featuredmedia"]?.[0]?.source_url || null,
                  slug:post.slug,
                  categoryName: post._embedded?.["wp:term"]?.[0]?.[0]?.name || 'Uncategorized',
                  
              }));
              // console.log(simplifiedPosts);
              setPosts(simplifiedPosts); 
          })
          .catch(error => console.error(error));
  }, []);
  return (
    <Routes>
      <Route path="/" element={<Popup />} />
      <Route path="/home" element={<Home posts={posts} />} />
      <Route path="/ai-blog" element={<AiBlog posts={posts} />} />
      <Route path="/ai-tools" element={<AiTools posts={posts} />} />   
      <Route path="/ai-trader" element={<AiTrader posts={posts} />} />   
      <Route path="/webinar" element={<Webinar posts={posts}  />} />
      <Route path="/single/:slug" element={<SinglePost posts={posts}/>} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />   
      <Route path="*" element={<Page404 posts={posts} />} /> 
   
</Routes>
  );
}

export default App;
