import React from 'react'
import NavBar from '../../components/NavBar'
import Footer from '../../components/Footer'
import Banner from '../../components/Banner'
import bannerData from "../../data/bannerData"
import Blogs from '../../components/Blogs'


const AiBlog = ({posts}) => {
    return (
        <>
            <NavBar posts={posts}  />
            <Banner data={bannerData["ai-blog"]} />
            <Blogs data={posts} category='ai-blog' />
            <Footer />
        </>
    )
}

export default AiBlog