import React from 'react'
import NavBar from '../../components/NavBar'
import Footer from '../../components/Footer'
import Banner from '../../components/Banner'
import Blogs from '../../components/Blogs'
import bannerData from "../../data/bannerData"
const AiTools = ({posts}) => {
    return (
        <>
            <NavBar posts={posts}  />
            <Banner data={bannerData["ai-tools"]} />
            <Blogs data={posts} category='ai-tools' />
            <Footer />
        </>
    )
}

export default AiTools