import React, { useState } from 'react';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import Banner from '../../components/Banner';
import bannerData from '../../data/bannerData';
import webinarsData from '../../data/webinarsData';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper/modules";
import 'swiper/css/bundle';
import { WebinarsIconButton, WebinarsBox } from '../../styles/webinarStyled';
import { Container, Stack, Button, Typography, Box, Card, CardMedia, CardContent } from '@mui/material';
import { PlayArrow } from '@mui/icons-material';

const url = webinarsData[webinarsData.length - 1].videoId
const previuosWebinars = webinarsData.slice(0, webinarsData.length - 1);


const Webinar = ({posts}) => {
    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlayClick = () => {
        setIsPlaying(true); // Show the YouTube iframe
    };

    return (
        <>
            <NavBar posts={posts}  />
            <Banner data={bannerData.webinars} />
            <WebinarsBox>
                <Container
                    maxWidth="md"
                    component="section"
                    sx={{ padding: '80px 30px !important' }}
                >
                    <Stack gap="40px" alignItems="center">
                        <Box
                            sx={{
                                position: 'relative',
                                width: '100%',
                                height: 0,
                                paddingBottom: '56.25%',
                                borderRadius: '8px',
                                overflow: 'hidden',
                                backgroundImage: isPlaying
                                    ? 'none'
                                    : `url(https://img.youtube.com/vi/${url}/maxresdefault.jpg)`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                            }}
                        >
                            {isPlaying ? (
                                <iframe
                                    width="100%"
                                    height="100%"
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                    }}
                                    src={`https://www.youtube.com/embed/${url}?autoplay=1`}
                                    title='Webinar Video'
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            ) : (
                                <WebinarsIconButton
                                    color="secondary"
                                    onClick={handlePlayClick}
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                >
                                    <PlayArrow sx={{ fontSize: 60, color: 'white' }} />
                                </WebinarsIconButton>
                            )}
                        </Box>
                        <Typography
                            color="secondary"
                            sx={{ fontWeight: '600', textAlign: 'center' }}
                        >
                            Webinar Title
                        </Typography>
                        <Typography>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae,
                            quod id voluptatum libero impedit officia vero laudantium
                            molestiae perspiciatis veniam consequatur dolores dolore laborum
                            excepturi. Fuga rem earum minima! Dolores. <br />
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui
                            perspiciatis fugiat vel tempora accusantium expedita inventore.
                            Laborum, rem neque fugiat doloribus consequatur nulla! Eos
                            consequatur sit est necessitatibus velit voluptatibus! <br />
                            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minus
                            dolorem quia veritatis dicta molestiae ad aut voluptatem
                            necessitatibus consequatur, eum magnam sequi earum dolores facilis
                            inventore dignissimos eligendi ex tempore?
                        </Typography>
                        <Stack sx={{ position: 'relative', width: '100%' }}>
                            <Swiper
                                breakpoints={{
                                    0: { slidesPerView: 1 },
                                    820: { slidesPerView: 2 },
                                    1100: { slidesPerView: 3 },
                                }}
                                loop={true}
                                modules={[Navigation]}
                                navigation={{
                                    nextEl: '.swiper-button-next',
                                    prevEl: '.swiper-button-prev',
                                }}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    position: 'relative',
                                    margin: '0 auto',
                                    maxWidth: '100%'
                                }}
                            >
                                {previuosWebinars.map((item, index) => (
                                    <SwiperSlide key={index} style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Card sx={{ border: 'none', backgroundColor: 'transparent', maxWidth: "300px !important" }}>
                                            <CardMedia sx={{ backgroundImage: `url(https://img.youtube.com/vi/${item.videoId}/maxresdefault.jpg)`, width: '300px', height: '169px', position: 'relative' }}>
                                                <Button component="a"
                                                    href={`https://www.youtube.com/watch?v=${item.videoId}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    sx={{ position: 'absolute', top: '50%', left: '50%', transform: "translateY(-50%) translateX(-50%)", background: "#00FBF4", borderRadius: '50%', opacity: '1', '&:hover': { opacity: '0.8' } }}>
                                                    <PlayArrow sx={{ fontSize: 60, color: '#fff', }} />
                                                </Button>
                                            </CardMedia>
                                            <CardContent sx={{ padding: '10px 0' }}>
                                                <Typography variant='subtitle1'> {item.name}</Typography>

                                            </CardContent>
                                        </Card>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <div className="swiper-button-next" ></div>
                            <div className="swiper-button-prev"></div>
                        </Stack>
                    </Stack>
                </Container>
            </WebinarsBox >

            <Footer />
        </>
    );
};

export default Webinar;
