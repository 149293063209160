import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import {
    BlogsBox,
    BlogsCard,
    BlogsCardMedia,
    BlogsCardContent,
    BlogsTypographyTitle,
    BlogsTypographyContent,
    BlogsBoxHover,
    BlogsButton
} from '../styles/blogsStyled';
import Fortrade from './Fortrade';
import { LatestBlogsTypographyButton } from '../styles/homeStyled';
import { Stack, Container } from '@mui/material';

const Blogs = ({ data, category }) => {
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const postsPerPage = 4; 

    const filteredData = category
    ? data.filter(item => item.categoryName === category)
    : data;

    const modifiedData = [
        ...filteredData.slice(0, 2),
        { type: 'fortrade' },  // special item to represent Fortrade
        ...filteredData.slice(2)
    ];   

    // Calculate the posts to display for the current page
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = modifiedData.slice(indexOfFirstPost, indexOfLastPost);
    
    const handleChangePage = (event, value) => {
        setCurrentPage(value);  // Update the current page
    };

    return (
        <BlogsBox component='article'>
            <Container maxWidth="lg">
                <Stack gap='50px' alignItems='center'>
                    {/* Render only the posts for the current page */}
                    {currentPosts.map((item, index) => {
                        if (item.type === 'fortrade') {
                            return <Fortrade key="fortrade" />;
                        } else {
                          
                            return (
                                <BlogsCard key={index}>
                                    <BlogsCardMedia
                                        component='div'
                                        sx={{ backgroundImage: `url(${item.featuredImage})` }}
                                        title={item.name}
                                        onMouseEnter={() => setHoveredIndex(index)}
                                        onMouseLeave={() => setHoveredIndex(null)}
                                    >
                                        {hoveredIndex === index && (
                                            <BlogsBoxHover>
                                                <Link
                                                    to={`/single/${item.slug}`}
                                                >
                                                    <BlogsButton
                                                        size="medium"
                                                        color="secondary"
                                                        variant="outlined"
                                                    >
                                                        <LatestBlogsTypographyButton>
                                                            Read More
                                                        </LatestBlogsTypographyButton>
                                                    </BlogsButton>
                                                </Link>
                                            </BlogsBoxHover>
                                        )}
                                    </BlogsCardMedia>
                                    <BlogsCardContent>
                                        <BlogsTypographyTitle color='secondary'>
                                            {item.title}
                                        </BlogsTypographyTitle>
                                        {/* I need to do this so it loads html from data. */}
                                        <BlogsTypographyContent dangerouslySetInnerHTML={{ __html: item.content }} />
                                    </BlogsCardContent>
                                </BlogsCard>
                            );
                        }
                    })}
                    {/* Pagination component */}
                    <Pagination
                        count={Math.ceil(modifiedData.length / postsPerPage)} // Total pages
                        page={currentPage} 
                        onChange={handleChangePage}
                        color="secondary"
                    />
                </Stack>
            </Container>
        </BlogsBox>
    );
}

export default Blogs;
