import React from "react"
import {
    BannerBoxBg,
    BannerBoxContainer,
    BannerBoxBlue,
    BannerBoxWhite,
    BannerTypographySubtitle
} from "../styles/componentStyled"
import { Typography, Box } from "@mui/material"



const Banner = ({ data }) => {

    return (
        // because BannerBg and BannerContainer are styled components I cannot use atribute like in box "component" that's why I use "as"
        <BannerBoxBg as='main' sx={{ position:'relative',}}>
            <BannerBoxContainer
                as='div'
                sx={{                   
                    minHeight: data.bannerHeight,
                    alignItems: data.bannerAlign
                }}
            >
                         <Box sx={{position:'absolute', top:'0', left:'0', width:'100%',
                height:'100%',background:'rgba(0,0,0, 0.5)',pointerEvents:'none'
            }}></Box>
                <Typography variant="h1" textAlign='center' sx={{textShadow:'3px 3px 8px rgba(0, 0, 0, 0.7)',position:'absolute'}}>
                    <BannerBoxBlue component="span" >
                        {data.blueMain}
                    </BannerBoxBlue>
                    <BannerBoxWhite component="span" >
                        {data.whiteMain}
                    </BannerBoxWhite>
                </Typography>
                <BannerTypographySubtitle variant="subtitle1" sx={{position:'absolute',top:"60%"}} >
                    {data.subtitle}
                </BannerTypographySubtitle>
            </BannerBoxContainer>
        </BannerBoxBg>
    )
}

export default Banner