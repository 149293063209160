import React from 'react'
import NavBar from '../../components/NavBar'
import Footer from '../../components/Footer'
import Banner from '../../components/Banner'
import bannerData from "../../data/bannerData"
import Blogs from '../../components/Blogs'

const AiTrader = ({posts}) => {
  return (
    <>
        <NavBar posts={posts}  />
        <Banner data={bannerData["ai-trader"]} />
        <Blogs data={posts} category='ai-trader' />
        <Footer />
    </>
  )
}

export default AiTrader